import Cookies from "js-cookie"
import { addBreadcrumb } from "@sentry/nextjs"
import type { RequestInit } from "next/dist/server/web/spec-extension/request"
import type { ErrorMessage } from "types/ErrorMessage"
import ErrorWithData from "./ErrorWithData"

export const AUTH_COOKIE_PATH = "Authorization"
export const SESSION_COOKIE_PATH = "sessionid"
export const XCSRF_COOKIE_PATH = "X-CSRFToken"
export const CSRF_COOKIE_PATH = "csrftoken"
export const MASQUERADE_TYPE_COOKIE_PATH = "MasqueradeType"
export const MASQUERADE_SLUG_COOKIE_PATH = "MasqueradeSlug"

export const API_URL =
  process.env.NEXT_PUBLIC_API_URL || "http://localhost:8000"

// export const API_URL =
//   process.env.NEXT_PUBLIC_API_URL || "https://staging-app.rad.inc"

type Message = {
  [key: string]: any
}

type GenericHeaders = {
  [propName: string]: string
}

async function processResponse<T = Message>(r: Response) {
  const response = r

  let data: ErrorMessage | Message | string | null = null
  if (response.status === 204) {
    return {
      data: null,
      response,
    } as any as {
      data: T
      response: Response
    }
  }
  try {
    data = await r.text()
    data = JSON.parse(data)
  } catch (e) {
    console.warn("bad JSON response", e)
  }
  if (!response.ok) {
    addBreadcrumb({
      category: "data",
      message: response?.statusText || "Unknown error",
      data: data as ErrorMessage,
      level: "info",
    })
    addBreadcrumb({
      category: "data",
      message: "API Error URL",
      data: { url: response.url },
      level: "info",
    })
    addBreadcrumb({
      category: "data",
      message: "Current Masquerade",
      data: {
        type: Cookies.get(MASQUERADE_TYPE_COOKIE_PATH),
        slug: Cookies.get(MASQUERADE_SLUG_COOKIE_PATH),
      },
      level: "info",
    })
    throw new ErrorWithData(
      response?.statusText || "Unknown error",
      typeof data === "object"
        ? { ...(data as { [k: string]: any }), response, url: response.url }
        : { data: data || null, url: response.url, response },
      response?.status
    )
  }
  return {
    data,
    response,
  } as {
    data: T
    response: Response
  }
}

/**
 * Fetch and send data to / from your main data provider / CMS
 * Provides a simple mechanism for using different APIs for different environments
 * Auth + standardised error handling can easily be implemented across the codebase
 *
 * @see https://bitbucket.org/josephmark/oxamii-portal/src/main/lib/api.ts#lines-88
 */
export const API = {
  headers: {
    "X-Masquerade-Type": Cookies.get(MASQUERADE_TYPE_COOKIE_PATH) ?? null,
    "X-Masquerade-Slug": Cookies.get(MASQUERADE_SLUG_COOKIE_PATH) ?? null,
    "Content-Type": "application/json",
  } as GenericHeaders,
  token: Cookies.get(AUTH_COOKIE_PATH) ?? null,
  csrf_token: Cookies.get(CSRF_COOKIE_PATH) ?? null,
  masqueradeType: Cookies.get(MASQUERADE_TYPE_COOKIE_PATH),
  masqueradeSlug: Cookies.get(MASQUERADE_SLUG_COOKIE_PATH),
  api_url: API_URL,
  initializeTokens() {
    if (this.token) {
      this.headers.Authorization = `Token ${this.token}`
    }
    if (this.csrf_token) {
      this.headers[XCSRF_COOKIE_PATH] = this.csrf_token
    }
  },
  async get<T = Message>(
    route: string,
    options: Omit<RequestInit, "cache"> & {
      cache?: RequestInit["cache"] | "false"
      headers?: GenericHeaders
    } = {
      headers: {},
    }
  ) {
    const { headers, ...restOptions } = options
    return fetch(new URL(route, API_URL), {
      ...restOptions,
      headers: this.mergeAndCleanHeaders(headers),
      cache:
        options.cache === "false" ? undefined : options.cache || "no-store",
      // next: options.next || {
      //   revalidate: 30,
      // },
    }).then<ReturnType<typeof processResponse<T>>>(processResponse)
  },
  async modify<T = Message>(
    // eslint-disable-next-line default-param-last
    method = "POST",
    route: string,
    data: { [propName: string]: any } | FormData = {},
    headers: GenericHeaders = {}
  ) {
    const cleanedHeaders = this.mergeAndCleanHeaders(headers)
    if (typeof window !== "undefined" && data instanceof FormData) {
      delete cleanedHeaders["Content-Type"]
    }
    return fetch(new URL(route, API_URL), {
      method,
      headers: cleanedHeaders,
      body:
        typeof window !== "undefined" && data instanceof FormData
          ? data
          : JSON.stringify(data),
    }).then<ReturnType<typeof processResponse<T>>>(processResponse)
  },
  async post<T = Message>(
    route: string,
    data: { [propName: string]: any } = {},
    headers: GenericHeaders = {}
  ) {
    return this.modify<T>("POST", route, data, headers)
  },
  async put<T = Message>(
    route: string,
    data = {},
    headers: GenericHeaders = {}
  ) {
    return this.modify<T>("PUT", route, data, headers)
  },
  async patch<T = Message>(
    route: string,
    data = {},
    headers: GenericHeaders = {}
  ) {
    return this.modify<T>("PATCH", route, data, headers)
  },
  async delete<T = Message>(
    route: string,
    data?: Record<string, any>,
    headers: GenericHeaders = {}
  ) {
    return this.modify<T>("DELETE", route, data, headers)
  },
  setXCSRFToken(csrf_token: string) {
    this.csrf_token = csrf_token
    this.headers[XCSRF_COOKIE_PATH] = csrf_token
    return this
  },
  setSession(csrftoken?: string, sessionid?: string) {
    const cookieParts = []
    if (csrftoken) {
      cookieParts.push(`${CSRF_COOKIE_PATH}=${csrftoken}`)
    }
    if (sessionid) {
      cookieParts.push(`${SESSION_COOKIE_PATH}=${sessionid}`)
    }
    if (cookieParts.length > 0) {
      this.headers.Cookie = cookieParts.join("; ")
    }
    return this
  },
  stripSession() {
    this.csrf_token = null
    delete this.headers[XCSRF_COOKIE_PATH]
    delete this.headers.Cookie
    return this
  },
  setToken(key: string) {
    this.token = key
    this.headers.Authorization = `Token ${key}`
    return this
  },
  stripToken() {
    this.token = null
    delete this.headers.Authorization
    return this
  },
  setMasquerade(type: "artist" | "brand", slug: string) {
    this.masqueradeType = type
    this.masqueradeSlug = slug
    this.headers["X-Masquerade-Type"] = type
    this.headers["X-Masquerade-Slug"] = slug
    return this
  },
  stripMasquerade() {
    this.masqueradeType = null
    this.masqueradeSlug = null
    delete this.headers["X-Masquerade-Type"]
    delete this.headers["X-Masquerade-Slug"]
    return this
  },
  mergeAndCleanHeaders(newHeaders = {}) {
    const merged = { ...this.headers, ...newHeaders }

    return Object.keys(merged)
      .filter(
        (key) =>
          merged[key] !== null &&
          merged[key] !== undefined &&
          merged[key] !== ""
      )
      .reduce((acc, key) => {
        acc[key] = merged[key]
        return acc
      }, {})
  },
}

API.initializeTokens()
export default API
